import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import rootReducers from '../reducers/index'

export const history = createBrowserHistory()

const reduxDevTools =
  process.env.NODE_ENV !== 'production' &&
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()

const store = createStore(
  rootReducers(history),
  {},
  compose(applyMiddleware(routerMiddleware(history)), reduxDevTools || compose)
)

export default store
