import { AuthState } from '../types/authState'

export const UPDATE_AUTHENTICATION_STATUS = 'UpdateAuthenticationStatus'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function UpdateAuthenticationStatus(auth: AuthState | null) {
  return {
    type: UPDATE_AUTHENTICATION_STATUS,
    auth: auth
  }
}
