import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import authentication from './auth'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const rootReducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    authentication
  })

export default rootReducers
