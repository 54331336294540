export class NetworkConstants {
  public static URL_HOME = '/'
  public static URL_ABOUT = '/about'
  public static URL_CONTACT = '/contact'
  public static URL_PRIVACY_POLICY = '/privacy-policy'
  public static URL_LOGIN = '/_login'
  public static URL_SIGN_UP = '/_sign-up'
  public static URL_MALT_LANDING = '/malt'

  public static URL_ADMIN_SITUATIONS = '/admin/situations'
  public static URL_ADMIN_SITUATIONS_SHOW = '/admin/situations/:id'

  public static URL_CHANGE_PASSWORD = '/change-password'
  public static URL_FORGOT_PASSWORD = '/forgot-password'
  public static URL_SITUATIONS = '/situations'
  public static URL_SITUATION = '/situations/:id'
  public static URL_TOPIC = '/situations/:situationId/:id'
  public static URL_CODE_VERIFICATION = '/code-verification'

  public static URL_PACKS = '/packs'

  public static URL_PAYMENT_CONFIRMATION_PAGE = '/payment-confirmation'
  public static URL_WAITLIST_CONFIRMATION_PAGE = '/waitlist-confirmation'

  public static URL_RENEW_CARLOS = '/renew-carlos'
  public static URL_RENEW_YODRA = '/renew-yodra'
  public static URL_RENEW_MIREIA = '/renew-mireia'

  public static URL_CUSTOM_OFFER = '/offer/'
  public static URL_SUBSCRIPTION_DYNAMIC_OFFER = '/subscription/offer/:token/:contactId'

  public static URL_SERVICE_NOT_AVAILABLE = '/service-not-available'

  public static DEPRECATED_URLS = ['/login', '/sign-up']
}
