import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const urlLanguage = window.location.pathname.substr(1, 2)
if (urlLanguage === 'es' || urlLanguage === 'en') {
  console.log(`Setting language from URL to: ${urlLanguage}`)
  localStorage.setItem('currentLanguage', urlLanguage.toUpperCase())
}

const currentLanguage = localStorage.getItem('currentLanguage')

/* Random value used to force the translation files to fetch a new file instead of using the disk cache */
const randomValue = Math.random()

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: currentLanguage ? currentLanguage : 'es',
    backend: {
      /* translation file path */
      loadPath: `/assets/i18n/{{ns}}/{{lng}}.json?_=${randomValue}`
    },
    fallbackLng: 'es',
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    cleanCode: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    },
    requestOptions: {
      cache: 'max-age=0, no-cache, must-revalidate'
    }
  })

export default i18n
