import React from 'react'
import { useTranslation } from 'react-i18next'
import LanguageSelector from './LanguageSelector'
import { Modal } from 'react-bootstrap'

type HamburgerProps = {
  visible: boolean
  onClose: () => void
}

const HamburgerMenu = ({ visible, onClose }: HamburgerProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Modal show={visible} onHide={onClose} className="hamburger-menu">
      <Modal.Body>
        <div className="big-content-container">
          <div className="modal--header">
            <div className="title">
              <span>
                <a href="/">English4Devs</a>
              </span>
            </div>
            <div onClick={onClose} className="hamburger-menu__close">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.51019 2.97663C-0.170063 2.29567 -0.170063 1.19234 0.51019 0.511372C1.18934 -0.169042 2.29207 -0.169594 2.97232 0.511372L23.4901 21.0506C24.1698 21.7305 24.1692 22.8344 23.4895 23.5153C22.8098 24.1957 21.7077 24.1957 21.0274 23.5159L0.51019 2.97663Z"
                  fill="#E6E4DF"
                />
                <path
                  d="M2.9726 23.5153C2.29289 24.1957 1.19017 24.1957 0.509915 23.5148C-0.170339 22.8343 -0.169788 21.731 0.510466 21.05L21.0271 0.510793C21.7074 -0.169621 22.8096 -0.170724 23.4898 0.510242C24.1701 1.19066 24.1701 2.29509 23.4898 2.97605L2.9726 23.5153Z"
                  fill="#E6E4DF"
                />
              </svg>
            </div>
          </div>
          <div className="modal--content">
            <div className="hamburger-contact-us">
              <div className="hamburger-title">Contact us</div>
              <div className="links">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="background-light-brown"
                  href="mailto:hi@english4devs.com"
                >
                  <div className="contact-us--button">
                    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.6178 0H4.56362C2.18126 0 0.878906 1.55172 0.878906 3V9C0.878906 10.9397 2.78479 12 4.56362 12H15.6178C17.3966 12 19.3025 10.9397 19.3025 9V3C19.3025 1.55172 18.0001 0 15.6178 0ZM17.4601 8.81896C17.4601 10.319 15.6178 10.319 15.6178 10.319V3.56897L10.0907 6.56896L4.56362 3.56897V10.319C4.56362 10.319 2.72126 10.319 2.72126 8.81896V2.81897C2.72126 2.81897 2.72126 1.31897 4.56362 1.31897L10.0907 4.7069L15.6178 1.31897C17.4601 1.31897 17.4601 2.81897 17.4601 2.81897V8.81896Z"
                        fill="#001847"
                      />
                    </svg>
                    Mail
                  </div>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="background-light-brown"
                  href="https://t.me/english4devs"
                >
                  <div className="contact-us--button">
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.677 0.345982C17.8284 0.465353 17.8825 0.66144 17.8366 0.817019L17.7897 0.933525L12.3419 13.7839C12.3185 13.8422 12.2707 13.9196 12.2221 13.9579C12.1248 14.0347 12.0266 14.0723 11.8779 14.0702C11.6553 14.0865 11.4301 13.9856 11.303 13.847L7.91066 9.63798L16.2653 1.41928L5.86769 8.06555L0.529419 5.39082C0.353697 5.29063 0.225714 5.11301 0.246404 4.93753C0.292327 4.78195 0.437364 4.6278 0.659008 4.57242L16.9571 0.276983L17.1048 0.240057C17.2769 0.183954 17.5256 0.22661 17.677 0.345982Z"
                        fill="#001847"
                      />
                    </svg>
                    Telegram
                  </div>
                </a>
              </div>
            </div>

            <div className="hamburger-title">{t('header.languageSelector')}</div>
            <div onClick={onClose}>
              <LanguageSelector />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default HamburgerMenu
