import { UPDATE_AUTHENTICATION_STATUS } from '../actions/auth'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const authentication = (state = { auth: null }, action: any) => {
  switch (action.type) {
    case UPDATE_AUTHENTICATION_STATUS:
      return {
        auth: action.auth
      }
    default:
      return state
  }
}
export default authentication
