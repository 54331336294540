import i18n from 'i18next'

const createPathWithLang = (path: string): string => {
  const lang = i18n.language ? i18n.language.toLowerCase() : 'es'
  const pathWithLanguage = `/${lang}${path}`
  return pathWithLanguage
}

const createPathForRouter = (path: string): string => {
  const pathWithLanguage = `/:langId(es|en)${path}`
  return pathWithLanguage
}

export { createPathWithLang, createPathForRouter }
