import React from 'react'
import Modal from 'react-bootstrap/Modal'
import LoadingSpinner from '../atoms/LoadingSpinner'

interface LoadingOverlayProps {
  isShowing: boolean
  loadingText?: string
}

const LoadingOverlay = ({ isShowing, loadingText }: LoadingOverlayProps): JSX.Element => {
  return (
    <div className="loading-overlay" data-testid="loading-overlay">
      <Modal show={isShowing} aria-labelledby="contained-modal-title-vcenter" centered animation>
        <Modal.Body className="p-4">
          <LoadingSpinner text={loadingText} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default LoadingOverlay
