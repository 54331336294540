import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { history } from '../../../stores/store'

const LanguageSelector = (): JSX.Element => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language: string) => {
    setSelectedLanguage(language)
    localStorage.setItem('currentLanguage', language)
    i18n.changeLanguage(language)
    const newUrl = `/${language.toLowerCase()}${window.location.pathname.substring(3)}`
    history.push(newUrl)
  }

  const getStoredLanguage = (): string => {
    return localStorage.getItem('currentLanguage') || 'ES'
  }

  const checkmark = (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3444 1.00654C13.2181 1.02667 13.1014 1.09274 13.013 1.19425C10.0655 4.45188 8.7242 6.27839 5.81943 9.51037L1.93187 6.08246C1.81308 5.97766 1.66141 5.92918 1.51022 5.94771C1.35904 5.96623 1.22071 6.05025 1.12569 6.18125C1.03066 6.31225 0.986711 6.47951 1.00351 6.64624C1.02031 6.81298 1.09648 6.96554 1.21526 7.07034L5.51488 10.8638C5.6252 10.96 5.76372 11.0081 5.9041 10.9989C6.04449 10.9898 6.17694 10.9242 6.27627 10.8144C9.34556 7.42214 10.7735 5.45942 13.8192 2.0932C13.9081 2.00112 13.968 1.88016 13.9903 1.74791C14.0126 1.61566 13.9961 1.47901 13.9432 1.35783C13.8902 1.23665 13.8036 1.13727 13.6959 1.07409C13.5883 1.01091 13.4651 0.987244 13.3444 1.00654Z"
        fill="#00112F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.31793 11.0899L5.31659 11.0887L1.01698 7.29525C0.837973 7.13732 0.729108 6.91347 0.705211 6.67627C0.68132 6.43915 0.743136 6.19792 0.88304 6.00505C1.02354 5.81136 1.23441 5.67924 1.47393 5.64989C1.71402 5.62047 1.95031 5.69848 2.13047 5.8574C2.13049 5.85742 2.13051 5.85744 2.13053 5.85746L5.79488 9.08854C7.1052 7.62365 8.09616 6.44594 9.08504 5.27071L9.17085 5.16873C10.2377 3.90094 11.3124 2.62694 12.789 0.994788C12.9209 0.844547 13.0988 0.741883 13.2974 0.710234L13.3445 1.00551L13.2973 0.710256C13.2973 0.710249 13.2973 0.710241 13.2974 0.710234C13.4884 0.67974 13.6817 0.717741 13.848 0.815295C14.0136 0.912484 14.1416 1.06209 14.2183 1.23769C14.2949 1.41309 14.3182 1.60888 14.2863 1.79775C14.2548 1.98491 14.1698 2.16045 14.0389 2.29767C12.5243 3.97179 11.411 5.29771 10.2925 6.62981L10.2865 6.6369C9.16499 7.97258 8.0376 9.31502 6.49891 11.0156L6.31602 10.8501L6.49891 11.0156C6.35026 11.1799 6.14635 11.2838 5.92372 11.2983C5.70085 11.3127 5.48527 11.2358 5.31793 11.0899ZM13.8194 2.09316C13.9083 2.00107 13.9682 1.88011 13.9905 1.74787C14.0128 1.61562 13.9963 1.47897 13.9434 1.35779C13.8904 1.23661 13.8038 1.13722 13.6961 1.07405C13.5885 1.01087 13.4653 0.987201 13.3446 1.0065C13.2183 1.02663 13.1016 1.09269 13.0132 1.19421C11.4997 2.86696 10.4097 4.16237 9.31406 5.46449C8.27598 6.6982 7.23283 7.93793 5.81962 9.51033L1.93205 6.08242C1.81327 5.97762 1.6616 5.92914 1.51041 5.94766C1.35923 5.96619 1.2209 6.0502 1.12588 6.1812C1.03085 6.3122 0.986901 6.47946 1.0037 6.6462C1.0205 6.81294 1.09667 6.96549 1.21545 7.07029L5.51507 10.8637C5.62539 10.9599 5.76391 11.008 5.90429 10.9989C6.04468 10.9898 6.17713 10.9241 6.27646 10.8143C7.81415 9.11484 8.93987 7.77416 10.0634 6.43603C11.1825 5.10321 12.2995 3.77293 13.8194 2.09316Z"
        fill="#00112F"
      />
    </svg>
  )

  const [selectedLanguage, setSelectedLanguage] = useState(getStoredLanguage())

  return (
    <div className="language-selector">
      <div className="mobile-hide">
        <DropdownButton
          alignRight={true}
          title={t('header.languageSelector')}
          onSelect={(language) => changeLanguage(language || 'ES')}
        >
          <Dropdown.Item as="button" eventKey="EN" className={selectedLanguage === 'EN' ? 'active' : ''}>
            English {selectedLanguage === 'EN' && checkmark}
          </Dropdown.Item>
          <Dropdown.Item as="button" eventKey="ES" className={selectedLanguage === 'ES' ? 'active' : ''}>
            Español {selectedLanguage === 'ES' && checkmark}
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="desktop-hide tablet-hide">
        <button className={selectedLanguage === 'ES' ? 'active' : ''} onClick={() => changeLanguage('ES')}>
          Español {selectedLanguage === 'ES' && checkmark}
        </button>
        <button className={selectedLanguage === 'EN' ? 'active' : ''} onClick={() => changeLanguage('EN')}>
          English {selectedLanguage === 'EN' && checkmark}
        </button>
      </div>
    </div>
  )
}

export default LanguageSelector
