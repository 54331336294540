import React from 'react'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

interface LoadingSpinnerProps {
  text?: string
}

const LoadingSpinner = ({ text }: LoadingSpinnerProps): JSX.Element => {
  return (
    <div className="loading-spinner">
      <Row className="d-flex flex-row justify-content-center">
        <Spinner animation="grow" />
        <h4 className="ml-sm-2 loading-text">{text ? text : 'Loading...'}</h4>
      </Row>
    </div>
  )
}

export default LoadingSpinner
