import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'
import HamburgerMenuSvg from '../../../assets/svgs/Hamburger-Menu.svg'
import RegularHamburgerMenu from './HamburgerMenu'
import MaltHamburgerMenu from '../MaltLanding/MaltLandingHamburgerMenu'
import LanguageSelector from './LanguageSelector'

const LandingHeader = ({ ..._ }: any, ref: React.LegacyRef<HTMLElement>) => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)
  const [hasScrolledDown, sethasScrolledDown] = useState(false)
  const scrollThreshold = 0

  const { t } = useTranslation()

  const location = window.location.pathname
  const isOnMaltPage = () => location.split('/').slice(2).toString() === 'malt'

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (!document.scrollingElement) return
      const scrolled = document.scrollingElement.scrollTop

      sethasScrolledDown(scrolled > scrollThreshold)
    })

    return () =>
      window.removeEventListener('scroll', (_) => {
        return
      })
  }, [])

  const handleOpenHamburgerMenu = () => {
    setIsHamburgerMenuOpen(true)
  }

  const handleCloseHamburgerMenu = () => {
    setIsHamburgerMenuOpen(false)
  }

  const renderHamburgerMenu = () => {
    return isOnMaltPage() ? (
      <MaltHamburgerMenu visible={isHamburgerMenuOpen} onClose={handleCloseHamburgerMenu} />
    ) : (
      <RegularHamburgerMenu visible={isHamburgerMenuOpen} onClose={handleCloseHamburgerMenu} />
    )
  }

  return (
    <header ref={ref} className={`home landing-header ${hasScrolledDown ? '--scrolled' : ''}`} id="landing-header">
      <div className={'big-content-container '}>
        <div className="header-flex-wrapper">
          <div className="header-links">
            <div className="title">
              <span>
                <a href="/">English4Devs</a>
              </span>
            </div>

            {isOnMaltPage() && (
              <div className="mobile-hide tablet-hide">
                <div className="section-links">
                  <Link className="header--link" to="malt-why-e4d" smooth offset={-200} duration={500} spy hashSpy>
                    {t('maltLanding.header.whyE4D')}
                  </Link>
                  <Link className="header--link" to="malt-week-at-club" smooth offset={-200} duration={500} spy hashSpy>
                    {t('maltLanding.header.howClubWorks')}
                  </Link>
                  <Link
                    className="header--link"
                    to="malt-offer-wrapper"
                    smooth
                    offset={-200}
                    duration={500}
                    spy
                    hashSpy
                  >
                    {t('maltLanding.header.joinNow')}
                  </Link>
                  <Link className="header--link" to="malt-know-more" smooth offset={-200} duration={500} spy hashSpy>
                    {t('maltLanding.header.contactUs')}
                  </Link>
                  <LanguageSelector />
                </div>
              </div>
            )}
          </div>
          {isOnMaltPage() ? (
            <div className="desktop-hide " onClick={handleOpenHamburgerMenu}>
              <HamburgerMenuSvg />
            </div>
          ) : (
            <React.Fragment>
              <div className="mobile-hide">
                <LanguageSelector />
              </div>
              <div className="desktop-hide tablet-hide" onClick={handleOpenHamburgerMenu}>
                <HamburgerMenuSvg />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>

      {renderHamburgerMenu()}
    </header>
  )
}

export default React.forwardRef(LandingHeader)
