import React from 'react'

const MailSVG = (): JSX.Element => {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.313 4.62292C18.4552 4.5099 18.6667 4.61562 18.6667 4.79427V12.25C18.6667 13.2161 17.8828 14 16.9167 14H1.75C0.783854 14 0 13.2161 0 12.25V4.79792C0 4.61562 0.207812 4.51354 0.353646 4.62656C1.17031 5.26094 2.25312 6.06667 5.97187 8.76823C6.74115 9.32969 8.03906 10.5109 9.33333 10.5036C10.6349 10.5146 11.9583 9.30781 12.6984 8.76823C16.4172 6.06667 17.4964 5.25729 18.313 4.62292ZM9.33333 9.33333C10.1792 9.34792 11.3969 8.26875 12.0094 7.82396C16.8474 4.31302 17.2156 4.00677 18.3312 3.13177C18.5427 2.96771 18.6667 2.7125 18.6667 2.44271V1.75C18.6667 0.783854 17.8828 0 16.9167 0H1.75C0.783854 0 0 0.783854 0 1.75V2.44271C0 2.7125 0.123958 2.96406 0.335417 3.13177C1.45104 4.00312 1.81927 4.31302 6.65729 7.82396C7.26979 8.26875 8.4875 9.34792 9.33333 9.33333Z"
        fill="white"
      />
    </svg>
  )
}

export default MailSVG
