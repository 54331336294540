import Amplify, { Auth } from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.css'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { history } from './stores/store'
import LoadingOverlay from './components/molecules/LoadingOverlay'
import Main from './components/pages/Main'
import * as serviceWorker from './serviceWorker'
import store from './stores/store'
import './styles/index.scss'
import { getAuthStateFromCognitoUser, setAuthState } from './utils/AuthenticationUtils'
import './i18n'
import TagManager from 'react-gtm-module'

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID
  }
})

const tagManagerArgs = {
  gtmId: 'GTM-PQWKTN6'
}

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs)
}

const jsx = (
  <Provider store={store}>
    <Router history={history}>
      <Suspense fallback={null}>
        <Main />
      </Suspense>
    </Router>
  </Provider>
)

const loading = <LoadingOverlay isShowing={true} loadingText="Loading page..." />

let hasRenderedPage = false

const renderApplication = () => {
  if (!hasRenderedPage) {
    ReactDOM.render(jsx, document.getElementById('root'))
    hasRenderedPage = true
  }
}

Auth.currentAuthenticatedUser()
  .then((cognitoUser) => {
    const authState = getAuthStateFromCognitoUser(cognitoUser)
    setAuthState(authState)
    renderApplication()
  })
  .catch((_error) => {
    renderApplication()
  })

ReactDOM.render(loading, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
