import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-scroll'
import i18n from 'i18next'
import XSvg from '../../../assets/svgs/X.svg'
import { history } from '../../../stores/store'

type HamburgerProps = {
  visible: boolean
  onClose: () => void
}

const MaltLandingHamburgerMenu = ({ visible, onClose }: HamburgerProps): JSX.Element => {
  const { t } = useTranslation()
  const locale = i18n.language ? i18n.language.toLowerCase() : 'en'

  const isSelectedLanguage = (language: string) => locale === language
  const selectLanguage = (event: React.ChangeEvent<HTMLInputElement>, language: string) => {
    event.preventDefault()
    if (isSelectedLanguage(language)) return
    i18n.changeLanguage(language)
    const newUrl = `/${language.toLowerCase()}${window.location.pathname.substring(3)}`
    history.push(newUrl)
    onClose()
  }

  return (
    <Modal animation={false} show={visible} onHide={onClose} id="malt-hamburger-menu">
      <Modal.Body>
        <div className="hamburger-title">
          <div className="close-btn" onClick={onClose}>
            <XSvg />
          </div>
        </div>
        <div className="hamburger-content">
          <div className="links">
            <Link
              className="header--link--mobile"
              to="malt-why-e4d"
              smooth
              offset={-200}
              duration={500}
              onClick={onClose}
              spy
              hashSpy
            >
              {t('maltLanding.whyE4D.title')}
            </Link>
            <Link
              className="header--link--mobile"
              to="malt-week-at-club"
              smooth
              offset={-200}
              onClick={onClose}
              duration={500}
              spy
              hashSpy
            >
              {t('maltLanding.howClubWorks.title')}
            </Link>
            <Link
              className="header--link--mobile"
              to="malt-offer-wrapper"
              smooth
              offset={-200}
              duration={500}
              onClick={onClose}
              spy
              hashSpy
            >
              {t('maltLanding.offer.buttonText')}
            </Link>
            <Link
              className="header--link--mobile"
              to="malt-know-more"
              smooth
              offset={-200}
              duration={500}
              onClick={onClose}
              spy
              hashSpy
            >
              {t('footer.contactUs')}
            </Link>
          </div>
          <div className="language-zone">
            <div className="language-zone__title">{t('header.languageSelector')}</div>
            <div className="language-zone__list">
              <a
                href="# "
                onClick={(e: any) => selectLanguage(e, 'en')}
                className={`${isSelectedLanguage('en') ? 'active' : ''}`}
              >
                English
              </a>
              <span className="separator">/</span>
              <a
                href="# "
                onClick={(e: any) => selectLanguage(e, 'es')}
                className={`${isSelectedLanguage('es') ? 'active' : ''}`}
              >
                Español
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MaltLandingHamburgerMenu
