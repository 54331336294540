import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ServiceNotAvailable = (): JSX.Element => {
  const { t } = useTranslation()

  useEffect(() => {
    const header = document.getElementById('landing-header')
    if (header) {
      header.setAttribute('white-bg', '')
      return () => {
        header.removeAttribute('white-bg')
      }
    }
  }, [])
  return (
    <section id="service-not-available" className="big-content-container">
      <span className="sad-face" role="img" aria-label="sad face">
        🥺
      </span>
      <h2 dangerouslySetInnerHTML={{ __html: t('serviceNotAvailable.headline') }}></h2>
      <h3>
        <span dangerouslySetInnerHTML={{ __html: t('serviceNotAvailable.description') }} />{' '}
        <a id="agile-monkey-link" target="_blank" rel="noopener noreferrer" href="mailto:hi@english4devs.com">
          hi@english4devs.com
        </a>
        .
      </h3>
      <p className="thanks-msg">
        <span dangerouslySetInnerHTML={{ __html: t('serviceNotAvailable.thankYou') }} />{' '}
        <span role="img" aria-label="wink face">
          😉
        </span>
      </p>
    </section>
  )
}

export default ServiceNotAvailable
