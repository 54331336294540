import React, { useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { NetworkConstants } from '../../utils/NetworkConstants'
import LandingHeader from './landingPageComponents/LandingHeader'
import LandingFooter from './landingPageComponents/LandingFooter'
import { useTranslation } from 'react-i18next'
import ServiceNotAvailable from './ServiceNotAvailable'
import { createPathForRouter, createPathWithLang } from '../atoms/LanguagePathUtil'

const Main = (): JSX.Element => {
  useTranslation()
  const location = useLocation()
  const headerRef = useRef<HTMLElement>(null)

  const isOnMaltPage = () => location.pathname.split('/')[2] === 'malt'
  const showHeaderAndFooter = (): boolean => {
    return location.pathname.split('/')[2] !== 'subscription' && location.pathname.split('/')[2] !== 'offer'
  }

  return (
    <div className={`main ${isOnMaltPage() ? 'malt-page' : ''}`} id="content-wrap">
      {showHeaderAndFooter() && <LandingHeader ref={headerRef} />}

      <div className="page-content">
        <Switch>
          <Route
            component={ServiceNotAvailable}
            path={createPathForRouter(NetworkConstants.URL_SERVICE_NOT_AVAILABLE)}
          />
          <Redirect to={createPathWithLang(NetworkConstants.URL_SERVICE_NOT_AVAILABLE)} />
        </Switch>
      </div>
      {showHeaderAndFooter() && <LandingFooter />}
    </div>
  )
}

export default Main
