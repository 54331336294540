import React from 'react'

const XSvg = (): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0207 10.0001L19.582 2.43858C20.1393 1.88046 20.1393 0.976705 19.582 0.418588C19.023 -0.139529 18.1187 -0.139529 17.5614 0.418588L10 7.97952L2.43864 0.418588C1.87993 -0.139529 0.976973 -0.139529 0.417974 0.418588C-0.139325 0.976705 -0.139325 1.88046 0.417974 2.43858L7.97933 10.0001L0.417974 17.5613C-0.139325 18.1186 -0.139325 19.0229 0.417974 19.5818C0.698182 19.8603 1.0631 20 1.42831 20C1.79465 20 2.15985 19.8606 2.43836 19.5818L9.99972 12.0209L17.5611 19.5818C17.8396 19.8603 18.2062 20 18.5711 20C18.9363 20 19.3013 19.8606 19.5815 19.5818C20.1388 19.0229 20.1388 18.1186 19.5815 17.5613L12.0207 10.0001Z"
        fill="#00112F"
      />
    </svg>
  )
}

export default XSvg
