import React from 'react'

const HamburgerMenuSvg = (): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.333 14.0663H30.383C31.7227 14.0663 32.8391 15.1827 32.8391 16.5223C32.8391 17.862 31.7227 18.9783 30.383 18.9783H10.333C8.99333 18.9783 7.87695 17.862 7.87695 16.5223C7.87695 15.138 8.94867 14.0663 10.333 14.0663Z"
        fill="#E6E4DF"
      />
      <path
        d="M18.997 0H30.3394C31.679 0 32.7954 1.11637 32.7954 2.45602C32.7954 3.79567 31.679 4.91204 30.3394 4.91204H18.997C17.6574 4.91204 16.541 3.79567 16.541 2.45602C16.541 1.11637 17.6574 0 18.997 0Z"
        fill="#E6E4DF"
      />
      <path
        d="M2.51852 28.0879H30.3832C31.7228 28.0879 32.8392 29.2043 32.8392 30.5439C32.8392 31.8836 31.7228 32.9999 30.3832 32.9999H2.51852C1.17887 32.9999 0.0625 31.8836 0.0625 30.5439C0.0625 29.2043 1.13422 28.0879 2.51852 28.0879Z"
        fill="#E6E4DF"
      />
    </svg>
  )
}

export default HamburgerMenuSvg
